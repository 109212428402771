//framework
import { DApp, MLUtils } from "@MoonLabsDev/dapp-core-lib";

//classes
import { Cache } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "../modules/Module_MaczkiAPI";

export class MaczkiAPI
{
    /////////////////////////
    // Singleton
    /////////////////////////

    static instance;
    static getInstance(dapp)
    {
        if (!this.instance)
        {
            this.instance = new MaczkiAPI(dapp || DApp.instance);
        }
        return this.instance;
    }

    /////////////////////////
    // Create
    /////////////////////////

    constructor(dapp)
    {
        this.url = `https://maczki.moon-labs.io/api.php`;
        this.dApp = dapp;

        //cached
        this.cache_maczkiToken_liquidityData = new Cache(async(_filter) => await this.maczkiToken_getLiquidityData(_filter, true), 60);
    }

    /////////////////////////
    // MACZKI Token
    /////////////////////////

    async maczkiToken_getLiquidityData(_filter, _forceReload)
	{
		_filter = _filter || {};
		if (_forceReload)
		{
			let data = undefined;
			try
			{
				data = await MLUtils.fetchJSON(this.makeURL(
					{
						module: "native",
						action: "liquidity",
						days: _filter.days || 180
					}
				));
			}
			catch (e) { }
			return data;
		}

		return await this.cache_maczkiToken_liquidityData.getData(_filter);
	}

    /////////////////////////
    // Helper
    /////////////////////////

	makeURL(_data)
	{
		return MLUtils.makeURL(this.url, _data);
	}

    findOrCreateCache(_cacheList, _id, _reloadCallback, _cacheTime)
    {
        if (!_cacheList[_id])
        {
            _cacheList[_id] = new Cache(_reloadCallback, _cacheTime);
        }
        return _cacheList[_id];
    }
}