import { configChains as cfg } from "@MoonVault/dapp-widgets-lib";

const configChains =
{
    defaultChain: 137,
    chains:
    [
		...cfg.chains.filter(c => c.id === 137)
    ]
};

export default configChains;