export default
{
	pages:
	{
		home:
		{
			intro:
			{
				description: "The most delicious project on the blockchain"
			},
			values:
			{
				"1":
				{
					title: "Whitepaper",
					text: "Read all there is to know about our sweet project right here"
				},
				"2":
				{
					title: "Roadmap",
					text: "Read about our spectaculair plans for the future"
				},
				"3":
				{
					title: "Security",
					text: "Our Products are audited for security and we completed a KYC at Rugdoc"
				}
			}
		},
		token:
		{
			intro:
			{
				header: "MACZKI Token",
				text: "Unique tokenomics and a token that can't loose value! A utility token for voting, early access, exclusive services & offers."
			},
			utility:
			{
				"1":
				{
					title: "Governance",
					text: "MACZKI Token holders will gain Governance points based on their token holding to vote on community proposals"
				},
				"2":
				{
					title: "Benefits",
					text: "Swap revenue sharing, partner offers, future launchpad revenue sharing & airdrops"
				},
				"3":
				{
					title: "Early Access",
					text: "MACZKI holders will be granted early access to new features and services that MACZKI develops, aswell as some exclusive services"
				}
			}
		}
	}

}