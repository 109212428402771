//components
import
{
    Text,
    Grid,
    Image,
    Card,
    CardBody,
    Flex,
} from "@MoonLabsDev/dapp-core-lib";
import
{
	MVPage,
} from "../dApp/components";

//framework
import
{
	useIsMobile,
	Translator as t
} from "@MoonLabsDev/dapp-core-lib";

//stlyes
import styles from "src/pages/Pages.module.css";

const Header = () =>
{
	const isMobile = useIsMobile();

	//const functions

    return (
		<Flex
			direction="row"
        >
			<Flex direction="column" basis={isMobile ? "100%": "38%"}>
				<Image
					src="assets/page/logo.png"
					style={{ height: "75px" }}
				/>
				<Text
					size={1}
					align="center"
				>
					{t("pages.home.intro.description")}
				</Text>
			</Flex>
            <Image
                src="/assets/page/home/mascottCoins.png"
				align="left"
                style=
                {
                    {
                        minWidth: "300px",
                        maxWidth: "95vw",
                        maxHeight: "400px"
                    }
                }
            />
        </Flex>
    );
};

const OurValue = ({title, image, children}) =>
{
    return (
        <Card
            style=
            {
                {
                    width: "300px",
                    height: "400px"
                }
            }
        >
            <CardBody>
				<Grid>
					<Flex>
						<Image
							src={image}
							style=
							{
								{
									height: "200px"
								}
							}
						/>
					</Flex>

					<Text
						size={1}
						color={3}
						align="center"
					>
						{title}
					</Text>

					<Text
						size={0}
						color={1}
						align="center"
					>
						{children}
					</Text>
				</Grid>
            </CardBody>
        </Card>
    );
};

const OurValues = () =>
{
    return (
		<Flex>
			<OurValue
				title={t("pages.home.values.1.title")}
				image="/assets/page/earn/token/example-section-1.png"
			>
				{t("pages.home.values.1.text")}
			</OurValue>

			<OurValue
				title={t("pages.home.values.2.title")}
				image="/assets/page/earn/token/example-section-2.png"
			>
				{t("pages.home.values.2.text")}
			</OurValue>

			<OurValue
				title={t("pages.home.values.3.title")}
				image="/assets/page/earn/token/example-section-3.png"
			>
				{t("pages.home.values.3.text")}
			</OurValue>
		</Flex>
    );
};

export const Page_home = () =>
{
    return (
		<MVPage>

			<Header />

			<OurValues />

		</MVPage>
    );
}